
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    TmFormLine,
  },
  setup() {
    const value = ref('')
    const checkbox1 = ref(false)
    const checkbox2 = ref(false)

    return {
      checkbox1,
      checkbox2,
      value,
    }
  },
})
